@charset "utf-8";


/***** Font *****/

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
$base-font-size: 19px;
$base-font-weight: 300;
$small-font-size: $base-font-size * 0.875;
$base-line-height: $base-font-size * 1.7;

$nav-height: 50px;
$nav-font-size: 21px;

/***** Layout *****/

$spacing-unit:     24px;


/***** Colour *****/

$text-color:       #111;
$background-color: #fefefe;
$nav-color:        #fafafa;
$border-color:     #ddd;
$brand-color:      #490085;
$photography-colour: #FF2600; /* Bright red */

$github-color: rgb(100, 6, 126);
$twitter-color: #55acee;

$link-color:      #c95b22; //#5cacfe
$apps-link-color: #09addf;
$post-meta-color: #555;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-phone:         460px;
$on-palm:          767px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
