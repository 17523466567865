/**
 * Site header
 */
.site-header {
    background: $nav-color;
    border-bottom: 1px solid $border-color;
}



/**
 * Site navigation
 */
ul.site-pages {
    list-style-type: none;
    margin: 0 0 8px;
    display: flex;

    li {
        font-size: $nav-font-size;
        font-weight: 200;
        flex-grow: 0;

        padding: 5px 20px 0;
        margin-top: $nav-height - $nav-font-size - 6px;
    }
    a:first-child li {
        padding-left: 0;
    }
    a:last-child li {
        padding-right: 0;
    }

    .site-title {
        $font-size: $nav-font-size * 1.5;
        font-size: $font-size;
        font-weight: 200;

        /* To align baseline */
        margin-top: $nav-height - $font-size;
    }
    .spacer { flex-grow: 1; }

    @include media-query(500px) {
        .site-title {
            padding-right: 0;
        }
        .collapsable {
            display: none;
            visibility: hidden;
        }
        li {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $border-color;
    padding: $spacing-unit/2 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;

    li {
        display: inline;
    }
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0 $spacing-unit/2;
}

.page-heading {
    font-size: 20px;
}

ul.post-list {
    margin-left: 0;
    list-style: none;
}

li.post-item {
    padding-top: $spacing-unit/2;
    padding-bottom: $spacing-unit/2;
    border-bottom: solid 1px #ddd;

    h2 {
        margin-top: 0.25em;
    }

    &:last-child {
        border-bottom: 0;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $post-meta-color;
    display: inline;
}

/* Archive page */
.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title a {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;
    color: black;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
    @include media-query($on-phone) {
        font-size: 24px;
        font-weight: 400;
    }
}

ul.post-categories {
    margin-left: 8px;
    display: inline;
    list-style-type: none;
    li {
        display: inline;
        background-color: #f2f2f2;
        color: #888;
        padding: 4px 8px;
        margin-left: 4px;
        font-size: 0.9em;
        border-radius: 4px;
    }
    li.post-category-feature-request a {
        font-variant: small-caps;
        color: purple;
    }
}

.post-content {
    margin-bottom: 2*$spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    .img-cont {
        margin: 2em 0;
    }

    img {
        max-width: 100%;
    }
}


/**
 * Slideshow
 */
.slideshow-cont {
    margin-top: 2em;
    position: relative;

    .slideshow-preview-cont {
        border-radius: 5px;
        padding: 10px 0 0;
        height: 600px;
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .slideshow-preview-wrapper {
        max-width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .slideshow-preview {
            background: #eee;
            max-height: 500px;
            max-width: 100%;
        }
    }
    .slideshow-caption {
        text-align: center;
        width: 88%;
        padding-bottom: 10px;
        min-height: 1em;
    }
    .slideshow-prev, .slideshow-next {
        width: 44px;
        height: 84px;
        text-align: center;
    }
    .hidden {
        display: none;
    }
}


/**
 * Footnote
 */

 .footnote-number {
    font-size: 0.75em;
 }


/**
 * Pagination
 */
.pagination {
    border-top: solid 1px #ddd;
    padding-top: $spacing-unit/4;
    padding-bottom: $spacing-unit/2;
    font-size: $base-font-size*0.8;
}


/**
 * Distribute elements horizontally using flexbox `space-between`
 */
.distribute-horizontally {
    display: flex;
    justify-content: space-between;
}



/**
 * Apps
 */
.app-cont {
    display: flex;
    flex-wrap: wrap;
    padding-top: $spacing-unit*3;
    padding-bottom: $spacing-unit*3;
    border-bottom: solid 1px #ddd;

    &:last-child {
       border-bottom: 0;
    }

    @include media-query($on-palm) {
        align-items: center;
    }
}

/* Side padding */
.app-cont:nth-child(even) {
    margin-left: -15%;
    .app-screen {
        text-align: right;
        order: 1;
    }
    .app-about {
        text-align: left;
        order: 2;
    }
}
.app-cont:nth-child(odd) {
    margin-right: -15%;
    .app-screen {
        text-align: left;
        order: 2;
    }
    .app-about {
        text-align: right;
        order: 1;
    }
}

.app-about {
    width: 50%;
    display: inline-block;
    margin-top: 12%;
    @include media-query($on-palm) {
        margin-top: 0;
    }

    & >:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
img.app-icon {
    width: 64px;
    border-radius: 10px;
    border: 1px solid #ccc;
}
.app-title {
    margin-top: 5px;
    font-size: 1.7em;
    color: #555;
}
.app-descr {
    font-size: 1rem;
    margin-top: 26px;
    margin-bottom: 30px;
    line-height: 1.3em;
    width: 70%;
    .app-cont:nth-child(odd) & {
        padding-left: 30%;
    }

    @include media-query($on-phone) {
        &, .app-cont:nth-child(odd) & {
        padding-left: 0;
        width: 100%;
    }
    }
}
.app-screen {
    max-height: 500px;
    width: 50%;
    display: inline-block;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
.app-caption {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    @include media-query($on-phone) {
        font-size: 14px;
    }
}


/**
 * Photography
 */
 .photography-colour {
    color: $photography-colour;
 }
section.photography {
    background: white;
    padding-top: 50px;
}
figure.photo {
    padding-top: 42px;
    padding-bottom: 42px;
    max-width: 800px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include media-query($on-phone) {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    @include media-query($on-palm) {
        max-width: 750px;
    }
    @include media-query(1000) {
        max-width: 800px;
    }
}
figure.photo img {
    max-width: 100%;
    height: auto;
}
figure.photo figcaption {
    color: gray;
    display: block;
    text-align: center;
    font-family: Courier, "Courier New", monospace;
}


/* Question (slightly bold) */
qq {
    font-weight: 400;
    display: inline;
}
