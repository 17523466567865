/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



html, body {
    overflow-x: hidden;
    max-width: 100%;
}
body {
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    font-family: $base-font-family;
    line-height: $base-line-height;
    color: $text-color;
    background-color: $nav-color;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: $spacing-unit;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a,
a:hover {
    color: $link-color;
    text-decoration: none;

    &:not(.no-underline):hover {
        text-decoration: underline;
    }
}
a.subtle-link:not(.active) {
    color: $grey-color;
    transition: color 300ms;
}
a.chevron-left:before {
    content: "‹";
    padding-right: 5px;
    display: inline-block;
    position: static;
}
a.chevron:after {
    content: "›";
    padding-left: 5px;
    display: inline-block;
    position: static;
}

.apps-colour,
.apps-colour:visited,
.apps-colour:hover,
.apps * a,
.apps * a:hover {
    color: $apps-link-color;
}


/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



.page-content {
    background-color: $background-color;
}


/**
 * Wrapper
 */
.wrapper {
    max-width: calc(#{$content-width});
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
    @extend %clearfix;
}



/**
 * Clearfix
 */
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-bottom: 3px;
        padding-right: 5px;

        path {
            fill: $grey-color;
        }
    }

    @include media-query($on-palm) {
        padding-right: 10px;
    }
}

.icon--github > svg:hover path {
    fill: $github-color;
}
.icon--twitter > svg:hover path {
    fill: $twitter-color;
}



/**
 * Images
 */
.images-cont {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    margin: $spacing-unit*1.5 40px;

    .img-cont {
        text-align: center;
    }

    .img-cont-1 {
        @extend .img-cont;
        width: 100%;
    }
    .img-cont-2 {
        @extend .img-cont;
        width: 50%;
    }

    @include media-query($on-palm) {
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .img-cont {
            width: 100%;
        }
        .img-cont + .img-cont {
            margin-top: $spacing-unit*1.5;
        }
    }
}

img.post-small {
    max-width: 260px;
}
img.post-medium {
    max-width: 280px;
}

div.caption {
    margin: $spacing-unit/4 auto 0;
    color: #555;
    width: 65%;
}
